import React from "react";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CookiesManager from "../components/molecules/CookiesManager";
import RGPDPage from "../components/pages/RGPDPage";
const theme = createTheme();

const RGPD = () => (
  <div id={"wrapper"}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Sympl</title>
    </Helmet>
    <ThemeProvider theme={theme}>
      <CookiesManager />
      <RGPDPage />
    </ThemeProvider>
  </div>
);
export default RGPD;
