import React from "react";
import { Body, MainContainer, Root, Title } from "./styledComponents";
import BodyContainer from "../../atoms/BodyContainer";

const RGPDPage = () => (
  <Root>
    <MainContainer>
      <BodyContainer>
        <Title>1. Préambule</Title>
        <Body>
          SAS DAT en tant qu’entreprise proposant des services de logistique,
          nous sommes amenés à traiter des données personnelles pour le compte
          de nos clients. Nous accordons donc une grande importance à la
          sécurité et à la confidentialité des données des personnes utilisant
          nos services. L’objectif de ce support est d’être transparent sur la
          façon dont nous traitons les données ainsi que les données à caractère
          personnel qui nous sont transmises, conformément au Règlement européen
          général sur la protection des données (RGPD). Celui-ci est entré en
          vigueur le 25 mai 2018. Ce texte a créé de nouvelles obligations pour
          les entreprises qui, comme SAS DAT, traitent des données personnelles
          et de nouveaux droits pour les personnes concernées par ces
          traitements, notamment le droit à l’oubli et le droit à la portabilité
          des données. Parmi les mesures mises en oeuvre par SAS DAT pour
          respecter les dispositions du RGPD, peuvent être notées : - La mise en
          place d’un cadre contractuel clair, où les obligations et
          responsabilités propres de SAS DAT et de ses clients en matière de
          collecte et de traitement des données personnelles sont précisément
          définies. - La tenue par SAS DAT d’un registre des traitements,
          identifiant et actualisant l’ensemble des traitements opérés sur les
          données personnelles qui lui sont transmises. - La mise en œuvre d’une
          démarche de sécurisation des données personnelles pour réduire autant
          que possible les risques de violation de ces données, et la mise en
          place d’un protocole d’information des clients en cas d'un tel
          évènement.
        </Body>
        <Title>
          2. Engagements vis-à-vis du traitement de vos données et du RGPD
        </Title>
        <Body>
          Nous nous sommes fixé un principe moral qui figure dans nos documents
          contractuels : SAS DAT ne vendra jamais les données collectées dans le
          cadre des services proposés. Nous gagnons notre vie en proposant des
          services de logistique, de préparation puis d’expédition de colis
          ainsi que des services autour de la logistique pour le e-commerce.
          Nous ne revendons pas les données des particuliers ou de nos clients.
          Ils et elles restent maîtres de leurs données. Par ailleurs, la
          réglementation impose deux autres notions très importantes. Nous nous
          engageons à ne collecter et traiter que les données qui sont
          nécessaires. Autrement dit : nous ne vous demanderons pas des données
          inutiles uniquement dans le but d'accumuler des informations. - Nous
          nous engageons à ne conserver les données collectées sous une forme
          permettant votre identification que pendant la durée nécessaire aux
          finalités pour lesquelles ces données sont collectées et traitées.
          C’est-à-dire que dès que vos données arrêtent de nous être
          indispensables au regard de la loi ou de notre fonctionnement, nous
          les effaçons ou les rendons anonymes.
        </Body>
        <Title>
          3. Nomination du délégué à la protection des données (DPO)
        </Title>
        <Body>
          Les articles 37 à 39 du RGPD précisent les situations pour lesquelles
          un délégué à la protection des données (ou DPO pour Data Protection
          Officer) doit être désigné, ainsi que ses missions. Le Client, en sa
          qualité de responsable de traitement et notre qualité de
          sous-traitant, nous nous devons de publier les coordonnées de nos DPO
          respectifs et les communiquer à l’autorité de contrôle, la CNIL. Pour
          communiquer avec notre délégué à la protection des données, veuillez
          envoyer un courriel à l'adresse dpo@sympl.fr
        </Body>
        <Title>
          4. Identification du responsable de traitement ou du sous-traitant des
          données
        </Title>
        <Body>
          En zone économique européenne (EEA), la loi sur la protection des
          données établit une distinction entre le « responsable de traitement »
          et le « sous-traitant » des informations. Selon les critères
          mentionnés dans le guide du sous-traitant, édicté en septembre 2017
          par la CNIL, le Client est le responsable du traitement des Données
          client. SAS DAT est le sous-traitant des Données de nos clients dans
          l'exécution de nos services et le responsable de traitement des Autres
          informations.
        </Body>
        <Title>5. Cartographie des données traitées</Title>
        <Body>
          Dans la mesure où des données sont associées à une personne physique
          identifiée ou identifiable et sont protégées en tant que données
          personnelles en vertu de la législation applicable sur la protection
          des données, elles sont désignées dans la présente Politique de
          confidentialité par le terme « Données personnelles ». Dans le cas où
          des informations personnelles sont transférées en dehors de l’Union
          européenne, nous nous assurons que le pays tiers concerné dispose d’un
          niveau de protection jugé adéquat par la réglementation européenne
          (RGPD). Si ça n’est pas possible, nous nous assurons que le transfert
          est réalisé conformément à la réglementation pour garantir la
          protection de ces informations. SAS DAT est amené à collecter ou
          recevoir différents types de données que nous pouvons classer de la
          façon suivante : - Des données ainsi que des données personnelles dans
          le cadre commercial et contractuel avec nos clients. - Des données
          ainsi que des données personnelles dans le cadre de l’exécution de
          notre service. - Des données ainsi que des données personnelles pour
          les mesures d’audience (analytics) et le bon fonctionnement de notre
          plateforme. - Des données personnelles dans le cadre du recrutement et
          de la gestion de nos collaborateurs. Nous utilisons de manière
          générale ces données pour : - Comme l'exigent les lois, les procédures
          juridiques ou les règlements applicables. - Communiquer avec vous en
          répondant à vos demandes, à vos commentaires et à vos questions. -
          Pour envoyer des courriels et d'autres communications. - Pour la
          facturation, la gestion des comptes et d'autres affaires
          administratives. - Mener des enquêtes et aider à prévenir les
          problèmes de sécurité et les abus.
        </Body>
        <Title>6. Gestion des données personnelles</Title>
        <Body>
          6.1 Gestion des données personnelles dans le cadre commercial et
          contractuel avec nos clients Sur quelles bases légales ? - Utilisateur
          de données publiques. - Consentement de l’utilisateur ou utilisatrice
          procédant à l’inscription. Que faites-vous avec ? - Émission de
          documents juridiques. - Communication avec le prospect ou client. -
          Accès au compte permettant de gérer le contrat. - Facturation Combien
          de temps conservez-vous ces données ? - 5 ans après la fin du contrat.
          - 2 ans après la dernière connexion si le contrat n’a pas été signé.
          Comment puis-je consulter ou modifier ces données ? Depuis le tableau
          de bord Sympl. Puis-je demander de supprimer les données qui me
          concernent ? - Si le contrat n’a pas été signé, oui. - Sinon, nous
          avons l’obligation de conserver les données. 6.2 Gestion des données
          personnelles dans le cadre de l’exécution de notre service Sur quelles
          bases légales ? Ces données sont nécessaires pour l'exécution du
          contrat passé avec SAS DAT. Elles doivent être recueillies légalement
          par le Responsable de Traitement (le Client). Que faites-vous avec ? -
          Préparation logistique et expédition de colis. - Gestion de litiges
          concernant des problématiques logistiques ou de transports avec nos
          tiers. Ces données sont transmises à nos tiers en charge du transport.
          Combien de temps conservez-vous ces données ? - 6 mois après
          l'expédition de la commande concernée, celle-ci est anonymisée. Seul
          le numéro de commande est conservé au titre de données pseudonymisées
          mais aucun lien ne peut être effectué dans notre système entre le
          numéro de commande et les données personnelles. - 3 mois après la date
          de création dans notre système si la commande ne nous a pas été
          transmise via l'un de nos services. - 10 ans pour les rescrits fiscaux
          et déclarations douanières comportant ces informations personnelles
          Comment puis-je consulter ou modifier ces données ? - Si vous êtes un
          client de SAS DAT, depuis votre le tableau de bord SAS DAT. - Si vous
          êtes la personne concernée par ces données personnelles, vous devez
          contacter le client de SAS DAT qui reste le responsable de traitement
          et propriétaire de ces données. Puis-je demander de supprimer les
          données qui me concernent ? - Si la commande a été expédiée, nous
          avons l’obligation de conserver les données 6 mois. - Si la commande a
          été expédiée et qu'elle fait l'objet d'une procédure de litige, nous
          avons l’obligation de conserver les données 6 mois après la clôture de
          ce litige et ne peuvent pas être supprimées durant toute la durée du
          litige. - Si la commande n'a pas été utilisée pour l'un de nos
          service, oui.
        </Body>
        <Title>7. Sous-traitants</Title>
        <Body>
          Les données collectées peuvent être communiquées en tant que de besoin
          aux partenaires, transporteurs, sous-traitants et prestataires. Ces
          transferts de données sont réalisés uniquement dans le cadre des
          opérations mentionnées et dans la limite nécessaire à l’exécution des
          tâches que nous confions aux tiers. Ces tiers sont pleinement informés
          par SAS DAT de la confidentialité des données qui leur sont
          communiquées dans ce cadre, et ces partenaires ont l’obligation
          d’assurer la protection de ces données. Par ailleurs, en vue de
          satisfaire les obligations légales et réglementaires, nous pouvons
          être amenés à communiquer des informations personnelles à des
          autorités administratives ou judiciaires sur leur demande. Dans cette
          hypothèse, nous veillons à ce que soient transmises uniquement les
          données strictement requises par les autorités. Ces sous-traitants
          incluent : 7.1 Techniques - Amazon Web Services (hébergement et
          stockage) - Monext (paiement) - Zendesk (gestion de tickets) 7.2
          Transports et Logistiques - Colissimo (Transporteur) - Chronopost
          (Transporteur) - Colis Privé (Transporteur) - Mondial Relay
          (Transporteur) - Spring (Transporteur) - Coursier.fr (Transporteur) -
          DHL (Transporteur) - Fedex (Transporteur) 7.3 Mesures d’audience et
          d'usage - Google Ads (publicité ciblée) - Google Analytics
          (analysed’audience)
        </Body>
        <Title>8. Contrat de sous-traitance des données (DPA)</Title>
        <Body>
          Nous incluons en annexe de nos conditions générales d’utilisation, un
          contrat de sous-traitance des données (ou data processing agreement
          «DPA») avec les entreprises clientes qui devient valable dès lors que
          celles-ci sont acceptées.
        </Body>
        <Title>9. Politique des cookies</Title>
        <Body>
          SAS DAT utilise des cookies et des technologies similaires dans ses
          sites Web et Services pour nous aider à collecter d’autres
          informations. Pour plus de détails sur notre utilisation de ces
          technologies, ainsi que sur vos possibilités d’exercer votre droit de
          refus et vos autres options, veuillez consulter notre POLITIQUE EN
          MATIERE DE COOKIES.
        </Body>
        <Title>10. Exercer vos droits</Title>
        <Body>
          Pour toute question concernant la sécurité et les données
          personnelles, ou pour vous permettre d’exercer vos droits d’accès, de
          rectification, de suppression, de retrait du consentement, de
          limitation du traitement, d’opposition au traitement ou de
          portabilité, vous pouvez contacter notre délégué à la protection des
          données à l'adresse dpo@SAS DAT.eu. Nous veillerons à ce qu’une
          réponse vous soit fournie dans les meilleurs délais. Pour toute
          réclamation concernant vos données personnelles, vous pouvez soit
          contacter notre DPO soit vous adresser directement à la Commission
          Nationale Informatique et Liberté (CNIL).
        </Body>
        <Title>POLITIQUE SAS DAT EN MATIERE DE COOKIES</Title>
        <Body>
          Lors de la consultation de notre site www. Sympl.fr, des informations
          sont susceptibles d'être enregistrées dans des fichiers "Cookies"
          installés dans votre ordinateur, tablette ou téléphone mobile. Cette
          page permet de comprendre ce qu’est un Cookie, à quoi il sert et
          comment on peut le paramétrer.
        </Body>
        <Body>QU’EST-CE QU’UN COOKIE ?</Body>
        <Body>
          Lors de la consultation de notre site www. Sympl.fr, des informations
          sont susceptibles d'être enregistrées dans des fichiers "Cookies"
          installés dans votre ordinateur, tablette ou téléphone mobile. Cette
          page permet de comprendre ce qu’est un Cookie, à quoi il sert et
          comment on peut le paramétrer.
        </Body>
        <Body>
          Il s’agit d’un fichier texte déposé dans un espace dédié du disque dur
          de votre terminal (ordinateur, tablette, téléphone mobile ou tout
          autre appareil optimisé pour Internet), lors de la consultation d’un
          contenu ou d’une publicité en ligne. Ce fichier Cookie ne peut être lu
          que par son émetteur. Il permet, à son émetteur, pendant sa durée de
          validité, de reconnaître le terminal concerné à chaque fois que ce
          terminal accède à un contenu numérique comportant des Cookies du même
          émetteur. Le Cookie ne vous identifie pas personnellement, mais
          uniquement le navigateur de votre terminal. À QUOI SERVENT LES COOKIES
          ? Différents types de Cookies, répondant aux finalités décrites
          ci-dessous, peuvent ainsi être enregistrés dans votre terminal à
          l’occasion d’une visite de notre site : 1. Les Cookies techniques sont
          nécessaires à la navigation dans notre site, ainsi qu’à l’accès aux
          différents produits et services. Les Cookies techniques permettent en
          particulier de vous reconnaître, de signaler votre passage sur telle
          ou telle page et ainsi d’améliorer votre confort de navigation :
          adapter la présentation du site aux préférences d'affichage de votre
          terminal (langue utilisée, résolution d'affichage), mémoriser les mots
          de passe et autres informations relatives à un formulaire que vous
          avez rempli sur le site (inscription ou accès à l’espace adhérents).
          Les Cookies techniques permettent également de mettre en œuvre des
          mesures de sécurité (c’est le cas par exemple lorsqu’il vous est
          demandé de vous connecter à nouveau à l’espace adhérent après un
          certain laps de temps). Ces Cookies ne peuvent pas être désactivés ou
          paramétrés sous peine de ne plus pouvoir accéder au site et/ou aux
          services du site. 2. Les Cookies de mesure d’audience sont émis par
          nous ou par nos prestataires techniques aux fins de mesurer l’audience
          des différents contenus et rubriques de notre site, afin de les
          évaluer et de mieux les organiser. Ces Cookies permettent également,
          le cas échéant, de détecter des problèmes de navigation et par
          conséquent d’améliorer l’ergonomie de nos services. Ces Cookies ne
          produisent que des statistiques anonymes et des volumes de
          fréquentation, à l’exclusion de toute information individuelle. La
          durée de vie de ces Cookies de mesure d’audience n’excède pas 13 mois.
          VOS CHOIX CONCERNANT LES COOKIES En utilisant notre site, vous
          consentez à l’utilisation des Cookies précités. Vous pouvez toutefois
          choisir à tout moment de désactiver tout ou une partie de ces Cookies,
          à l’exception des Cookies techniques nécessaires au fonctionnement du
          site comme indiqué ci-dessus. Votre navigateur peut également être
          paramétré pour vous signaler les Cookies qui sont déposés dans votre
          terminal et vous demander de les accepter ou pas. Refuser un Cookie
          par l’intermédiaire de votre logiciel de navigation Vous pouvez à tout
          moment choisir de désactiver tout ou partie des Cookies. Votre
          navigateur peut également être paramétré pour vous signaler les
          Cookies déposés dans votre terminal et vous demander de les accepter
          ou non (au cas par cas ou en totalité). Nous vous rappelons toutefois
          que la désactivation de tous les Cookies vous empêchera d’utiliser
          notre site dans des conditions normales, à l’exception des fonctions
          basiques. Pour Microsoft Internet Explorer 6.0 et au-delà : 1.
          Choisissez le menu "Outils" (ou "Tools"), puis "Options Internet" (ou
          "Internet Options"). 2. Cliquez sur l'onglet "Confidentialité" (ou
          "Confidentiality") 3. Sélectionnez le niveau souhaité à l'aide du
          curseur. Pour Microsoft Internet Explorer 5 : Pour Netscape 6.X et 7.
          X : Pour Netscape Communicator 4.X : Pour Opéra 6.0 et au-delà : Pour
          Firefox 2.0 et au-delà : Pour Chrome : 1. Choisissez le menu "Outils"
          (ou "Tools"), puis "Options Internet" (ou "Internet Options"). 2.
          Cliquez sur l'onglet "Sécurité" (ou "Security") 3. Sélectionnez
          "Internet" puis "Personnaliser le niveau" (ou "CustomLevel") 4.
          Repérez la rubrique "cookies" et choisissez l'option qui vous convient
          1. Choisissez le menu "Edition" "Préférences" 2. Confidentialité et
          Sécurité 3. Cookies 1. Choisissez le menu "Modifier" "Préférences" 2.
          Cliquez sur l'option "Avancé" 3. Rubrique "Cookies" 1. Choisissez le
          menu "Fichier" "Préférences" 2. Vie Privée 1. Choisissez le menu
          "Outils", puis "Options". 2. Choisissez le menu "Vie privée", puis
          cliquez dans "Cookies" 3. Décochez la case "Autoriser tous les sites à
          placer des cookies" 1. Choisissez le menu "Paramètres"". 2. Puis
          cliquez sur « Paramètres de contenu » puis cochez la case « Bloquer
          les cookies et les données de site tiers » En application des articles
          38 à 40 de la loi n° 78-17 du 6 janvier 1978, modifiée par la loi n°
          2004-801 du 6 août 2004, relative à l'informatique, aux fichiers et
          aux libertés, l’Utilisateur, ou le Client dispose d'un droit d'accès,
          de rectification, de modification et de suppression des données qui le
          concernent. Ce droit s’exerce de la manière suivante : Par courrier à
          l'attention de : SAS DAT – DPO 259 RUE SAINT HONORE 75001 PARIS Par
          e-mail : dpo@SAS DAT.eu
        </Body>
      </BodyContainer>
    </MainContainer>
  </Root>
);

export default RGPDPage;
